import { useRouter } from "next/router";
import { Fragment, useState } from "react";
import Link from "next/link";
import { HandySvg } from "handy-svg";
import { useStore } from "../../stores/use_global_state";
import getCommonLocales from "../../locales/locales_common";
import style from "./footer.module.scss";
import { FOOTER_LINKS, SOCIAL_LINKS } from "./constants";

function Footer({ editorAdaptive }) {
	const [openCategoryMenu, setOpenCategoryMenu] = useState("");
	const { state } = useStore();
	const router = useRouter();
	const { locale } = router;

	const dictionary = getCommonLocales(locale);

	const footerClassName = `${style.footer} ${editorAdaptive ? style.editorAdaptive : ""} ${state.error_code === "unavailable" ? style.disabled : ""} ${
		router.pathname === "/" ||
		router.pathname === "/tutorials" ||
		router.pathname === "/anime-generator" ||
		router.pathname === "/photo-to-sketch" ||
		router.pathname === "/blog" ||
		router.pathname === "/editor" ||
		router.pathname === "/affiliates"
			? style.colorBack
			: ""
	} `;

	return (
		<footer className={footerClassName}>
			<div className={style.left}>
				<Link href="/" aria-label="go to main page">
					<HandySvg className={style.logo} src={"/header/color_header_logo.svg"} width={196} height={60} />
				</Link>
				<div className={style.social}>
					<div className={style.icons}>
						{SOCIAL_LINKS.map((item) => (
							<a key={item.link} href={item.link} aria-label={`go to ${item.name}`} rel="noreferrer" target="_blank">
								<HandySvg src={item.image} width={40} height={40} fill={"transparent"} />
							</a>
						))}
					</div>
				</div>
				<div className={style.copyright}>Made by Deelvin © {new Date().getFullYear()}</div>
			</div>
			<div className={style.middle}>
				{FOOTER_LINKS.map((item) => (
					<div className={style.listWrapper} key={item.id}>
						{item.lists.map((list) => (
							<Fragment key={list.category}>
								<button
									className={`${style.listTitle} ${list.category === openCategoryMenu ? style.open : style.close}`}
									onClick={() => setOpenCategoryMenu(list.category === openCategoryMenu ? "" : list.category)}
								>
									{dictionary[list.category] || list.category} <HandySvg src={"/header/menu_arrow.svg"} width={13} height={8} style={{ fill: "none" }} />
								</button>
								<div className={style.grid}>
									<ul className={style.list}>
										{list.children.map((item) => {
											if (item.relAtr) {
												return (
													<li key={item.name} className={style.item}>
														<a key={item.name} className={style.link} href={item.url} rel="noreferrer" target="_blank">
															{dictionary[item.name]?.replace("<span>", "").replace("</span>", "") || ""}
														</a>
													</li>
												);
											}
											if (item.prefetchAtr) {
												if (item.name === "Affiliate Program" && locale === "ru") {
													return (
														<li key={item.name} className={style.item}>
															<Link className={style.link} href={item.url} prefetch={false}>
																Реферальная программа
															</Link>
														</li>
													);
												}
												if (item.name === "Affiliate Program" && locale !== "ru") {
													return (
														<li key={item.name} className={style.item}>
															<Link className={style.link} href={item.url} prefetch={false}>
																{item.name}
															</Link>
														</li>
													);
												}
												return (
													<li key={item.name} className={style.item}>
														<Link className={style.link} href={item.url} prefetch={false}>
															{dictionary[item.name]?.replace("<span>", "").replace("</span>", "") || item.name}
														</Link>
													</li>
												);
											}
											return (
												<li key={item.name} className={style.item}>
													<Link className={style.link} href={item.url}>
														{dictionary[item.name]?.replace("<span>", "").replace("</span>", "") || ""}
													</Link>
												</li>
											);
										})}
									</ul>
								</div>
							</Fragment>
						))}
					</div>
				))}
			</div>
		</footer>
	);
}

export default Footer;
